import React, { useState } from 'react'
import Breadcrumb from 'components/Breadcrumb';
import InstructionForm from './InstructionForm';

const LivePrompt = () => {

    const initialData = {
        unique_name: '',
        model_type: 'IBM',
        description:'',
        selectedModel: '',

        instruction: '',
        context: '',
        targetFormat: '',
        sampleInput: '',
        sampleOutput: '',
        inputData: '',

        groqParameters: {
            max_tokens: 200,
            n: 1,
            top_p: 1,
            stop: ["<|endoftext|>", "<|endofcode|>"],
            temperature: 0,
            frequency_penalty: 1.2,
            presence_penalty: 0,
            response_format: {"type": "text"},
        },

        watsonParameters: {
            decoding_method: "greedy",
            temperature: 0.7,
            min_new_tokens: 10,
            max_new_tokens: 200,
            top_p: 1, top_k: 50,
            repetition_penalty: 1.0,
            stop_sequence: ["<|endoftext|>", "<|endofcode|>"],
        }
    }
    
    const [instructionForm, setInstructionForm] = useState(initialData);

    return (
        
        <div className="flex flex-col w-full h-full p-8 overflow-y-scroll">
            <div className='my-3 px-2'>
                <Breadcrumb maxPaths={3} />
            </div>
            <div className="flex flex-row justify-between items-center my-4">
                <h1 className="text-xl font-bold">Prompt Lab</h1>
            </div>

            <InstructionForm form={instructionForm} setForm={setInstructionForm} update={false} />

        </div>
    );
};
export default LivePrompt;

