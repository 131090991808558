import React from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select"

export function FilterBar({ selectType, setSelectType, allListings, setListings, itemType='type', types, placeholder }) {

    const onChange = (value) => {
        setSelectType(value)
        if (value === 'All') {
            setListings(allListings)
        } else {
            setListings(allListings.filter(item => item[itemType] === value))
        }
    }

    return (
        <Select onValueChange={onChange} value={selectType}>
            <SelectTrigger className="w-[180px] bg-white" >
                <SelectValue placeholder={placeholder} />
            </SelectTrigger>
            <SelectContent>
                {types.map((type) => (
                    <SelectItem key={type.value} value={type.value}>
                        {type.name}
                    </SelectItem>
                ))}
            </SelectContent>
        </Select>
    )
}
