/* eslint-disable max-lines */
import React, { useContext, useState } from 'react'
import { initialInputGroups, OpCodeBuilderContext, STEP_TYPES } from './OpCodeBuilderHAndC/OpCodeBuilderContext'
import { Context } from 'context/GlobalState'
import handler from './OpCodeBuilderHAndC/OpCodeBuilderHandler'
import { Label } from 'components/ui/label'
import InputValidation from 'components/InputValidation'
import { Textarea } from 'components/ui/textarea'
import InputGroups from './InputGroups'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from 'components/ui/dialog'
import { ScrollArea } from 'components/ui/scroll-area'
import { Step, StepsContainer } from 'components/NewDesignComponents/VerticalSteps'
import { Button } from 'components/ui/button'
import { Card, CardContent } from 'components/ui/card'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import { opcodeService } from 'api/services/BEX/opcode.service'
// import { Input } from 'components/ui/input'

const OpCodeBuilderCreate = ({openModal, setOpenModal, scrollAreaRef}) => {
    const [opCodeName, setOpCodeName] = useState("");
    const [opCodeDescription, setOpCodeDescription] = useState("");
    const [inputGroups, setInputGroups] = useState(initialInputGroups);
    // const [userInput, setuserInput] = useState("")
    const [isCreating, setIsCreating] = useState(false);
    const [stepTypes, setStepTypes] = useState([STEP_TYPES[0].name]);
    const [opType, setOpType] = useState("");
    const { orgId, subOrgId, actions, opCodes, constantStepTypes, validateSteps } = useContext(OpCodeBuilderContext)
    const { addNewNotifcation } = useContext(Context);
    const parentId = window.crypto.randomUUID()

    // const removeDynamicInputsIds = (steps) => {
    //     // Iterate through each step in the array
    //     if(steps?.length > 0){
    //         steps.forEach((step) => {
    //             // Check if the step has the key 'dynamic_inputs_ids'
    //             if (step?.hasOwnProperty('dynamic_inputs_ids')) {
    //                 // Remove the key from the step
    //                 delete step.dynamic_inputs_ids;
    //             }
    //         });
    //     }

    //     return steps
    // }

    const createOpCode = async () => {
        // Validate the opCodeName first
        let { isValid: isOpCodeValid, message: opCodeMessage } = handler.validateOpCodeName(opCodeName.trim());
        if (!isOpCodeValid) {
            addNewNotifcation(opCodeMessage, "warning");
            return;
        }

        // Validate the inputGroups (steps)
        if (inputGroups.length < 2){
            addNewNotifcation("Please add at least one step to the opcode other than Identification.", "warning");
            return;
        }

        if(opType === "ChatOp" && inputGroups[inputGroups.length - 1].step_type !== "LLM-Stream"){
            addNewNotifcation("In type chat opcode, the final step must be LLM-Stream.", "warning");
            return;
        }

        let { isValid: areStepsValid, message: stepsMessage } = validateSteps(inputGroups);
        if (!areStepsValid) {
            addNewNotifcation(stepsMessage, "warning");
            return;
        }

        const opCodeData = {
            org_id: orgId,
            sub_org_id: subOrgId,
            opcode_id: opCodeName.trim(),
            description: opCodeDescription,
            type:opType,
            identification_step:inputGroups[0],
            steps: inputGroups.slice(1)
        };
    
        console.log("OpcodeData:", opCodeData);
    
        try {
            setIsCreating(true);
            const res = await opcodeService.createOpCode(opCodeData);
            console.log(res);
            addNewNotifcation("Opcode saved successfully.", "success");
            actions({type:"SET_OP_CODES", payload:[...opCodes, res.data]});
            setOpCodeName("");
            setOpCodeDescription("");
            setInputGroups([]);
            setOpenModal(false)
            if (scrollAreaRef.current) {
                scrollAreaRef.current.scrollTo({ top: scrollAreaRef.current.scrollHeight + 50, behavior: "smooth" });
            }
          
    
        } catch (error) {
            console.error("Error creating Opcode:", error);
            if (error.response.status === 409) {
                addNewNotifcation("Opcode already exists. Please choose another unique name.", "warning");
            } else {
                addNewNotifcation("Opcode cannot be created. Please try again.", "danger");
            }
        } finally {
            setIsCreating(false);
        }
    };

    const checkOpcodeName = () => {
        return (!handler.validateOpCodeName(opCodeName.trim())?.isValid || !opType || !opCodeDescription.trim())
    }

    const handleMoveStep = (dragIndex, hoverIndex, steps, parentIdContainer) => {
        try{
            if(parentId === parentIdContainer){
                let newInputGroups = []
                let newTypes = []
                // let i = 0
                steps?.map(step => {
                    if(step.props.indicator !== "S" && step.props.indicator !== "1" && step.props.indicator !== "E"){
                        const foundInput = inputGroups.find(inputGroup => inputGroup.step_id === step.props.step_id)
        
                        // const newDynamicInputs = {
                        //     ...foundInput, dynamic_inputs: foundInput.registry_type === 'api'? 
                        //     {"query_text":i === 0? "real_time_data" : ""} : i === 0? ["real_time_data"] : [],
                        // }
                        // const newInputGroup = foundInput.step_type === 'LLM' || foundInput.registry_type === 'api' 
                        // || foundInput.registry_type === 'function'? newDynamicInputs : foundInput
        
                        newInputGroups.push(foundInput)
        
                        newTypes.push(foundInput.step_type)
                        // i++
                    }
                })
                setInputGroups(newInputGroups)
                setStepTypes(newTypes)
            }
        }catch(err) {
            console.log(err)
        }
    }

    const handleTypeChange = (value) => {
        setOpType(value)
        actions({type:"SET_CONSTANT_TYPES", 
            payload:value === 'AutoOp'? [...STEP_TYPES.slice(0, 2), ...STEP_TYPES.slice(3)] : [...STEP_TYPES.slice(0, 1), ...STEP_TYPES.slice(2)]})
        setInputGroups(value === 'AutoOp'? inputGroups.filter(step=> step.step_type !== "LLM-Stream") : inputGroups.filter(step=> step.step_type !== "LLM"))
        setStepTypes(value === 'AutoOp'? stepTypes.filter(type => type !== "LLM-Stream") : stepTypes.filter(type => type !== "LLM"))
    }
    
    
    return (
        <Dialog open={openModal} onOpenChange={setOpenModal}>
            <DialogContent className="xl:max-w-[1250px] lg:max-w-[1000px] md:max-w-[850px] max-w-[700px] w-full p-0 bg-white rounded-lg shadow-lg">
                <ScrollArea className='lg:max-h-[680px] md:max-h-[580px] sm:max-h-[400px] p-4' >
                    <DialogHeader>
                        <DialogTitle className="text-2xl font-semibold">Create New Opcode</DialogTitle>
                        <DialogDescription className="text-md text-muted-foreground">Set up a new opcode.</DialogDescription>
                    </DialogHeader>
                    <StepsContainer onMoveStep={handleMoveStep} parentId={parentId} isNotNestead={true} className={'w-full'}>
                        <Step
                          indicator="S"
                          canAddBefore={false}
                          canAddAfter={false}
                          isDraggable={false}
                          canDelete={false}
                          isDisabled={false}
                          itemId={parentId}
                          title="Opcode Form"
                        >
                            <div className="space-y-4 w-full">
                                <div className="flex justify-between gap-2">
                                    <div className='w-full'>
                                        <Label>Opcode ID <span className='text-gray-400'>(Required)</span></Label>
                                        <InputValidation
                                            value={opCodeName}
                                            onChange={(e) => setOpCodeName(e.target.value)}
                                            placeholder="Enter a unique name for this opcode"
                                            validation={handler.validateOpCodeName}
                                        />
                                    </div>
                                    <div className='w-full'>
                                        <Label>Opcode Type <span className='text-gray-400'>(Required)</span></Label>
                                        <Select onValueChange={handleTypeChange} 
                                        id="opcode_type" 
                                        className="w-full" value={opType}
                                        >
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select opcode type" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectItem value={"AutoOp"}>{"Auto Opcode"}</SelectItem>
                                                <SelectItem value={"ChatOp"}>{"Chat Opcode"}</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </div>
                                </div>
                                <div>
                                    <Label>Opcode Description <span className='text-gray-400'>(Required)</span></Label>
                                    <Textarea 
                                    value={opCodeDescription} 
                                    className="min-h-[80px]"
                                    onChange={(e) => setOpCodeDescription(e.target.value)} 
                                    placeholder="Enter a description for this opcode" 
                                    />
                                </div>
                                {/* <div className='w-full flex gap-2'>
                                    <div className='w-full'>
                                        <Label htmlFor="file-upload">
                                            Upload a text file (optional)
                                        </Label>
                                        <Input
                                            id="file-upload"
                                            type="file"
                                            accept=".txt"
                                            disabled
                                            className="cursor-pointer"
                                            onChange={(e) => {
                                            const file = e.target.files?.[0];
                                            if (file) {
                                                // eslint-disable-next-line no-undef
                                                const reader = new FileReader();
                                                reader.onload = (event) => {
                                                const content = event.target?.result;
                                                if (typeof content === 'string') {
                                                    setuserInput(content);
                                                }
                                                };
                                                reader.readAsText(file);
                                            }
                                            }}
                                        />
                                    </div>
                                    <div className='w-full'>
                                        <Label htmlFor="user-input">
                                            Input for testing an instruction and workflow <span className='text-gray-400'>(real_time_data)</span>
                                        </Label>
                                        <Input
                                            id="user-input"
                                            value={userInput}
                                            disabled
                                            onChange={(e) => setuserInput(e.target.value)}
                                            placeholder="Enter input for execution or upload a text file"
                                        />
                                    </div>
                                </div> */}
                            </div>
                        </Step>
                        <InputGroups 
                        inputGroupsState={{inputGroups, setInputGroups}} 
                        stepTypesState={{stepTypes, setStepTypes}} 
                        otherProps={{parentId, constantStepTypes, orgId, subOrgId, opType, checkOpcodeName}}
                        />   
                        <Step
                        indicator="E"
                        canAddBefore={true}
                        canAddAfter={false}
                        isDraggable={false}
                        canDelete={false}
                        itemId={parentId}
                        isDisabled={checkOpcodeName()}
                        title="Opcode Summary and Create"
                        >
                            <div className='flex flex-col w-full'>
                                <Card className="mb-4 w-full">
                                    <CardContent className="pt-6">
                                        <h3 className="text-lg font-semibold mb-2">OpCode Summary</h3>
                                        <p><strong>Name:</strong> {opCodeName}</p>
                                        <p><strong>Description:</strong> {opCodeDescription || 'No description provided'}</p>
                                        <p><strong>Number of Steps:</strong> {inputGroups?.length}</p>
                                        <h4 className="font-semibold mt-2 mb-1">Step Types:</h4>
                                        <ul className="list-disc pl-5 flex gap-6">
                                            {stepTypes.map((type, index) => (
                                                <li key={index}>{type}</li>
                                            ))}
                                        </ul>
                                    </CardContent>
                                </Card>
                                <Button onClick={createOpCode} disabled={isCreating}>
                                    {isCreating ? "Creating..." : "Create"}
                                </Button>
                            </div>
                        </Step>
                    </StepsContainer>
                </ScrollArea>
            </DialogContent>
        </Dialog>
    )
}
export default OpCodeBuilderCreate