import { Card, CardContent, CardHeader } from "components/ui/card";
import { ScrollArea } from "components/ui/scroll-area";
import { EyeOffIcon, EyeIcon } from "lucide-react";
import { useState } from "react";
import { Switch } from "components/ui/switch";
import { Button } from "components/ui/button";

export function QueryResults({ results, onResultAction = null }) {
    const [viewerShownMap, setViewerShownMap] = useState({});

    const toggleViewer = (index) => {
        setViewerShownMap(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const handleCopyResultToContext = (resultText) => {
        if (onResultAction) {
            onResultAction(resultText);
        }
    };

    return (
        <ScrollArea className="max-h-[280px] h-fit">
            <div className="h-fit pr-4">
                {results.map((doc, index) => (
                    <Card key={index} className="w-full mb-4 bg-gray-100">
                        <CardHeader className="py-2 px-6">
                            <div className="grid grid-cols-2 gap-2 text-xs">
                                <div className="col-span-2">
                                    <span className="font-semibold">File Key: </span>
                                    <span className="text-gray-600 break-words">{doc.file_key}</span>
                                </div>
                                <div>
                                    <span className="font-semibold">Page Number: </span>
                                    <span>{doc.page_number || "-"}</span>
                                </div>
                                <div>
                                    <span className="font-semibold">Sheet Name: </span>
                                    <span>{doc.sheet_name || "-"}</span>
                                </div>
                                <div className="col-span-2">
                                    <span className="font-semibold">URL: </span>
                                    <a href={doc.presigned_url} target="_blank" className="hover:underline text-blue-400">{doc.document_name}</a>
                                </div>
                                <div>
                                    <span className="font-semibold">Score: </span>
                                    <span>{doc.distance}</span>
                                </div>
                            </div>
                        </CardHeader>
                        <CardContent className="space-y-2 py-2 px-6">
                            <div>
                                {onResultAction && 
                                    <Button onClick={() => handleCopyResultToContext(doc.text.trim())}>
                                        Copy To Context
                                    </Button>}
                                <h3 className="font-semibold mb-2 text-sm">Text:</h3>
                                <ScrollArea className="max-h-[150px] w-full rounded-md p-0 border bg-white">
                                    <p className="text-xs whitespace-pre-wrap p-2 border-none">{doc.text.trim()}</p>
                                </ScrollArea>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="flex items-center space-x-2">
                                    <Switch
                                        checked={viewerShownMap[index] || false}
                                        onCheckedChange={() => toggleViewer(index)}
                                    />
                                    {viewerShownMap[index] ? (
                                        <EyeIcon className="h-4 w-4 text-gray-500" />
                                    ) : (
                                        <EyeOffIcon className="h-4 w-4 text-gray-500" />
                                    )}
                                </div>
                                <div className="">
                                    {viewerShownMap[index] && doc.presigned_url && (
                                        doc.document_type === "application/pdf" ? (
                                            <iframe src={doc.presigned_url} frameborder="0" className="w-full h-[400px]"></iframe>
                                        ) : (
                                            <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(doc.presigned_url)}`} frameborder="0" className="w-full h-[400px]"></iframe>
                                        )
                                    )}
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                ))}
            </div>
        </ScrollArea>
    );
}