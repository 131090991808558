import React from 'react';
import AIChatBox from './AIChatBox';
import DocumentDisplay from './DocumentDisplay';
import { PageContent, PageLayout } from 'components/NewDesignComponents/PageLayout';
import Breadcrumb from 'components/Breadcrumb';

const AITeacher = () => {
    return (
        <PageLayout>
            <PageContent className="w-full p-8">
                <div className="flex flex-col w-full h-full">
                    <div className='my-3 px-2'>
                        <Breadcrumb maxPaths={3} />
                    </div>
                    <div className="w-full flex flex-row h-fit gap-4">
                        <div className="md:w-[480px] min-w-[480px] h-fit">
                            {/* chat box */}
                            <AIChatBox />
                        </div>
                        <div className="w-full h-fit">
                            {/* doc display */}
                            <DocumentDisplay/>
                        </div>
                    </div>
                </div>
            </PageContent>
        </PageLayout>

    );
};

export default AITeacher;