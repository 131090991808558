import React, { createContext, useState, useEffect, useContext } from 'react';
import handler from 'pages/Dashboard/SubOrganization/OpCodes/OpcodeBuider/OpCodeBuilderHAndC/OpCodeBuilderHandler';
import { OrganizationContext } from 'context/OrganizationContext';
import { SubOrgContext } from 'context/SubOrganizationContext';
import { opcodeService } from 'api/services/BEX/opcode.service';
import { instructionService } from 'api/services/BEX/instruction.service';
export const OpCodeBuilderContext = createContext({});

export const STEP_TYPES = [ 
    {id: 0, name: "Identification"}, 
    {id: 1, name: "LLM"}, 
    {id: 2, name: "LLM-Stream"}, 
    {id: 3, name: "Non-LLM"}, 
    {id: 4, name: "Condition"},
    // {id: 5, name: "Loop"},
]

export const initialInputGroups = [{
    step_id: window?.crypto?.randomUUID(),
    step_type: "Identification",
    unique_name: "",
    model_type: "",
    input: " {{context['real_time_data']}}",  
    output: { Output: "" },
}]

export const sideOptions = [
    { value: "context", label: "Dynamic Context Keys" },
    { value: "static", label: "Static Input string" },
    { value: "output", label: "Outputs From Prevoius Steps " },
    { value: "realtime", label: "Real Time Data" },
]
  
export const operatorOptions = [
    { value: ">", label: "Greater than" },
    { value: "<", label: "Less than" },
    { value: "==", label: "Equal to" },
    { value: ">=", label: "Greater than or equal to" },
    { value: "<=", label: "Less than or equal to" },
    { value: "!=", label: "Not equal to" },
    { value: "in", label: "In" },
    { value: "not in", label: "Not in" },
    { value: "is", label: "Is" },
    { value: "is not", label: "Is not" },
  //   { value: "and", label: "And" },
  //   { value: "or", label: "Or" },
]

export const OpCodeBuilderProvider = ({ organizationId=null, subOrganizationId=null, children }) => {
    const [opCodes, setOpCodes] = useState([]);
    const [isOpcodeLoading, setIsOpcodeLoading] = useState([]); 
    const [selectedOpCode, setSelectedOpCode] = useState(""); 
    const [constantStepTypes, setConstantStepTypes] = useState(STEP_TYPES)
    const {selectedOrganization} = useContext(OrganizationContext);
    const {selectedSubOrganization} = useContext(SubOrgContext);
    const [globalContext, setGlobalContext] = useState([])
    const [instructions, setInstructions] = useState([]); 
    const [IsInstructionLoading, setIsInstructionLoading] = useState([]); 
    const  orgId = organizationId? organizationId : selectedOrganization?._id 
    const  subOrgId = subOrganizationId? subOrganizationId : selectedSubOrganization?._id 

    const actions = (action) => {
        const { type, payload } = action;
        switch (type) {
            case "SET_OP_CODES":
                return setOpCodes(payload);
            case "SET_SELECTED_OPCODE":
                return setSelectedOpCode(payload);
            // case "SET_USER_INPUT":
            //     return setuserInput(payload);
            case "SET_CONSTANT_TYPES":
                return setConstantStepTypes(payload);
            case "SET_GLOBAL_CONTEXT":
                return setGlobalContext(payload);
            default:
                console.error(`Unhandled action type: ${type}`);
                return null;
        }
    };
    
    const handleGetSavedOpcodes = async () => {
        try {
            setIsOpcodeLoading(true)
            console.log("getting opcodes")
            const res = await opcodeService.listOpCodes(orgId, subOrgId);
            console.log(res);
            setOpCodes(res.data)
        } catch (error) {
            console.error("Failed to get saved models", error);
        } finally {
            setIsOpcodeLoading(false)
        }
    };

    const fetchInstructions = async () => {
        try {
            setInstructions([]); // Reset instructions
            setIsInstructionLoading(true);
            const res = await instructionService.listModels(
                orgId,
                subOrgId,
            );

            const instructios = res.data || [];
            if (instructios.length === 0) {
                console.log("No instructions saved.");
                return;
            }

            console.log("fetched models: ", instructios)

            const fetchedInstructions = instructios.map((instruction) => {
                const modifiedByMember = selectedOrganization.members.find(member => member.id._id === instruction.modified_by);
                const userIdMember = selectedOrganization.members.find(member => member.id._id === instruction.createdBy);
        
                return {
                    ...instruction,
                    modified_by: modifiedByMember ? modifiedByMember.id.name : instruction.modified_by,
                    user_id: userIdMember ? userIdMember.id.name : instruction.createdBy
                };
            });

            setInstructions(fetchedInstructions);
        } catch (error) {
            console.error("Error fetching instructions:", error);
        } finally {
            setIsInstructionLoading(false);
        }
    };

    function validateSteps(steps) {
        let errorMessages = [];
        
        steps.forEach((step, index) => {
            let stepErrors = [];
    
            // Validate LLM steps
            if (step.step_type === "LLM" || step.step_type === "LLM-Stream" || step.step_type === "Identification") {
                if (!step?.model_type) {
                    stepErrors.push("no model type");
                }
                if (!step?.unique_name) {
                    stepErrors.push("no instruction");
                }
                // Check if dynamic_inputs is an empty 
                if (step.input.trim() === "") {
                    stepErrors.push("no dynamic or static inputs");
                } 
            } 
            // Validate Non-LLM steps
            else if (step.step_type === "Non-LLM") {
                if (!step?.registry_key) {
                    stepErrors.push("no function selected");
                }
                if (step.registry_key === "KNOWLEDGE_RETRIEVAL_API" && !step?.input?.collection_id) {
                    stepErrors.push("no collection selected");
                }
                if (!step?.input || Object.keys(step.input).length === 0) {
                    stepErrors.push("no fixed inputs");
                }
                if (step?.input?.query_text?.trim() === "") {
                    stepErrors.push("no dynamic or static inputs");
                }
            }
            // Validate Condition steps
            else if (step.step_type === "Condition") {
                if (step?.input.trim() === "" || handler.isConditionIncomplete(step?.input)) {
                    stepErrors.push("no expression or some parts of it are missing");
                } 
              
                if (step?.next_step?.true_branch?.length === 0) {
                    stepErrors.push("no step selected in the true branch");
                }

                if (step?.next_step?.false_branch?.length === 0) {
                    stepErrors.push("no step selected in the false branch");
                }

            }

    
            // Validate output for both step types
            if(step.step_type !== "Identification" && step.step_type !== "Non-LLM" && step.step_type !== "Condition"){
                if (!step?.output?.Output) {
                    stepErrors.push("no output variable");
                } else {
                    const { isValid, message } = handler.validateOutputName(step?.output?.Output);
                    if (!isValid) {
                        stepErrors.push(`invalid output variable: ${message}`);
                    }
                }
            }

            if(step.step_type === "Non-LLM"){
                if (!Object.keys(step?.output)[0]) {
                    stepErrors.push("no output variable");
                } else {
                    const { isValid, message } = handler.validateOutputName(Object.keys(step?.output)[0]);
                    if (!isValid) {
                        stepErrors.push(`invalid output variable: ${message}`);
                    }
                }
            }
    
            if (stepErrors.length > 0) {
                errorMessages.push(`Step ${index + 1} has ${stepErrors.join(', ')}.`);
            }
        });
    
        return {
            isValid: errorMessages.length === 0,
            message: errorMessages.length > 0 ? errorMessages.join(' ') : "All steps are valid."
        };
    }
    
    useEffect(() => {
        handleGetSavedOpcodes()
        fetchInstructions()
    }, []);

    const value={
        opCodes,
        isOpcodeLoading,
        selectedOpCode,
        constantStepTypes,
        orgId,
        subOrgId,
        globalContext,
        instructions,
        IsInstructionLoading,
        actions, 
        handleGetSavedOpcodes,
        validateSteps,
        fetchInstructions
    }
    return (
        <OpCodeBuilderContext.Provider value={value}>
            {children}
        </OpCodeBuilderContext.Provider>
    );
}