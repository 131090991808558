import React from 'react'
import handler from '../../OpCodeBuilderHAndC/OpCodeBuilderHandler';
import InputValidation from 'components/InputValidation';
import { Label } from 'components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import InstructionSelectionModal from '../StepLLM/InstructionSelectionModal';
import NextStep from '../NextStep';
import DynamicInputSelector from '../DynamicInputSelector';
// import OutputMapSelector from '../OutputMapSelector';

const StepLLM = ({ inputGroup, stepIndex, inputGroups, setInputGroups}) => {
    const output = inputGroup?.output.Output

    const handleInputChange = (field, value) => {
            const newInputGroups = [...inputGroups];

            if (field === 'unique_name') {
                newInputGroups[stepIndex].unique_name = value;
            } else if (field === 'model_type') {
                newInputGroups[stepIndex].model_type = value;
                newInputGroups[stepIndex].unique_name = ""; 
            } else if (field === 'output') {
                newInputGroups[stepIndex].output.Output = value.trim();
            } else if (field === 'output_map') {
                newInputGroups[stepIndex].output.Output_map = value;
            } else if (field === 'input') {
                newInputGroups[stepIndex].input = value; 
            } 
            setInputGroups(newInputGroups);
    };

    return (
        <div className="grid grid-cols-2 lg:grid-cols-4 md:grid-cols-3 items-center gap-4 w-full">
            {/* <div>
                <Label htmlFor={`modelType-${stepIndex}`}>Instruction Type</Label>
                <Select onValueChange={(value) => handleInputChange("model_type", value)} value={inputGroup?.model_type || ""}>
                    <SelectTrigger className="w-full bg-white overflow-hidden">
                        <SelectValue placeholder="-" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="IBM">IBM</SelectItem>
                        <SelectItem value="Groq">Groq</SelectItem>
                    </SelectContent>
                </Select>
            </div> */}
            <div >
                <Label htmlFor={`uniqueName-${stepIndex}`}>Instruction Name</Label>
                <InstructionSelectionModal
                    group={inputGroup}
                    index={stepIndex}
                    inputGroups={inputGroups}
                    setInputGroups={setInputGroups}
                    model_type={inputGroup?.model_type}
                    />
            </div>

            <DynamicInputSelector 
            previousOutputs={handler.getPreviousOutputs(inputGroups, stepIndex)} 
            onCombinedResultChange={(result) => handleInputChange("input", result) }
            inputGroup={inputGroup}
            inputText={inputGroup?.input}
            inputGroups={inputGroups}
            setInputGroups={setInputGroups}
            stepIndex={stepIndex}
            />

            {inputGroup?.step_type !== "Identification" && (
                <>
                    <div className="flex flex-col justify-center gap-1 pt-1">
                        <Label htmlFor={`output-${stepIndex}`}>Step Output key</Label>
                        <InputValidation
                            id={`output-${stepIndex}`}
                            value={inputGroup?.output.Output || ""}
                            onChange={(e) => handleInputChange("output", e.target.value)}
                            className="bg-white"
                            validation={handler.validateOutputName}
                            disabled={handler.isOutputUsedInLaterSteps(inputGroups, output, stepIndex)}
                        />
                    </div>
                    {/* <div >
                        <Label htmlFor={`output-${index}`} className="flex items-center gap-2">
                            Step Output Map
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <AlertCircle className="h-4 w-4 cursor-pointer" />
                                    </TooltipTrigger>
                                    <TooltipContent className="max-w-xs">
                                        <p className="text-sm">
                                            Select outputs to create an output map. This map will be added to the final result without processing.
                                        </p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        </Label>
                        <OutputMapSelector 
                        previousOutputs={getPreviousOutputs(index)} 
                        onOutputMapChange={(result) => handleInputChange("output_map", result) }
                        inputGroup={inputGroup}
                        />
                    </div> */}
                    <div className="flex flex-col justify-center gap-1 pt-1">
                        <NextStep
                        inputGroup={inputGroup}
                        inputGroups={inputGroups}
                        setInputGroups={setInputGroups}
                        stepIndex={stepIndex}
                        />
                    </div>
                </>
            )}

            {inputGroup?.step_type === "Identification" && inputGroups?.length > 1 &&
                <div className="">
                    <Label htmlFor={`param-${stepIndex}`}>Next Step</Label>
                    <Select
                    disabled
                    value='-'
                    >
                        <SelectTrigger className="w-full bg-white">
                            <SelectValue placeholder="Select step" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem key={"default"} value={"-"}>
                                Step 2
                            </SelectItem>
                        </SelectContent>
                    </Select>
                </div>
            }
            
        </div>   
    );
}

export default StepLLM