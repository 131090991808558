import React from 'react'
import StyledTextArea from "../StyledTextArea";

const AnatomyLivePrompt = ({ instructionForm, setInstructionForm, isExecute=false, actions = {} }) => {

    return (
        <div className='flex flex-col gap-4 px-1 py-1'>
            <StyledTextArea
                value={instructionForm.instruction}
                onChange={(e)=> setInstructionForm(prev => ({...prev, instruction: e.target.value}))}
                labelText="Instruction"
                tooltipText="Provide specific tasks or questions for the language model to address. This sets the purpose
                and direction for the response."
                required={true}
                actions={actions?.instruction || []}
            />
            <StyledTextArea
                value={instructionForm.context}
                onChange={(e)=> setInstructionForm(prev => ({...prev, context: e.target.value}))}
                labelText="Context"
                tooltipText="Supply background information or details that help the language model understand the scenario
                or topic, ensuring relevant and accurate responses."
                required={false}
                actions={actions?.context || []}
                maxRows={20}
            />
            <StyledTextArea
                value={instructionForm.targetFormat}
                onChange={(e)=> setInstructionForm(prev => ({...prev, targetFormat: e.target.value}))}
                labelText="Target Format"
                tooltipText="Specify the structure and style of the response. This ensures that the output is consistent
                with your needs, whether it's a specific layout, tone, or type of information."
                required={false}
            />
            <div className="grid grid-cols-2 gap-4">
                <StyledTextArea
                    value={instructionForm.sampleInput}
                    onChange={(e)=> setInstructionForm(prev => ({...prev, sampleInput: e.target.value}))}
                    labelText="Sample Input"
                    tooltipText="Offer an example of what the input data might look like. This helps clarify the format
                    and type of data expected."
                    required={false}
                />
                <StyledTextArea
                    value={instructionForm.sampleOutput}
                    onChange={(e)=> setInstructionForm(prev => ({...prev, sampleOutput: e.target.value}))}
                    labelText="Sample Output"
                    tooltipText="Show a representative example of the desired response. This demonstrates the format
                    and quality of output you are looking for from the language model."
                    required={false}
                />
            </div>
            {isExecute && (
                <StyledTextArea
                disabled={!isExecute}
                value={instructionForm.inputData}
                onChange={(e)=> setInstructionForm(prev => ({...prev, inputData: e.target.value}))}
                labelText="Input Data"
                tooltipText="Include any data or details that the language model needs to process in order to generate
                a meaningful response. This can include text, numbers, or structured data."
                required={false}
                />
            )}
        </div>
    );
}

export default AnatomyLivePrompt