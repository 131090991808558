import { Label } from 'components/ui/label'
import React, { useState } from 'react'
import StepIdSelector from './StepIdSelector'
import ConditionBuilder from './ConditionBuilder'

const StepCondition = ({stepIndex, inputGroup, inputGroups, setInputGroups}) => {
    const [leftSide, setLeftSide] = useState("static")
    const [rightSide, setRightSide] = useState("static")
    const [operator, setOperator] = useState(">")
    const [leftValue, setLeftValue] = useState("")
    const [rightValue, setRightValue] = useState("")

    const handleExpressionChange = (value) => {
        if(value) {
            setInputGroups(inputGroups.map((group, i) => i === stepIndex? {...group, input:value || ""} : group ))
        }
    }

    return (
        <div className='grid grid-cols-3 items-center gap-4 w-full'>
            <div className='col-span-3'>
                <Label htmlFor="expression">Expression</Label>
                <ConditionBuilder
                stepIndex={stepIndex}
                inputGroups={inputGroups}
                setInputGroups={setInputGroups}
                onConditionChange={handleExpressionChange}
                initialCondition={inputGroup.input}
                leftState={{leftSide, setLeftSide, leftValue, setLeftValue}}
                rightState={{rightSide, setRightSide, rightValue, setRightValue}}
                opState={{operator, setOperator}}
                />
            </div>

            <StepIdSelector
            label="True Branch"
            isTrue={true}
            selectedStep={inputGroup?.next_step.true_branch || []}
            stepIndex={stepIndex}
            inputGroup={inputGroup}
            inputGroups={inputGroups}
            setInputGroups={setInputGroups}
            />

            <StepIdSelector
            label="False Branch"
            isTrue={false}
            selectedStep={inputGroup?.next_step.false_branch || []}
            stepIndex={stepIndex}
            inputGroup={inputGroup}
            inputGroups={inputGroups}
            setInputGroups={setInputGroups}
            />
        </div>
    )
}   

export default StepCondition