/* eslint-disable max-lines */
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import React, { useEffect, useState } from 'react'
// import NextStep from '../NextStep'
import { ArrowLeftCircle, ArrowRightCircle, MinusCircle } from 'lucide-react'
import { Button } from 'components/ui/button'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import NestedSteps from './NestedSteps'

const StepLoop = ({index, inputGroup, inputGroups, userInput, setInputGroups}) => {
    const [collectionArr, setCollectionArr] = useState([""])
    const allowedDelimiters = [",", ";", "|", ":"];
    const parentId = window.crypto.randomUUID()

    useEffect(() => {
        if(inputGroup.fixed_inputs.collection){
            const res= splitStringByDelimiters(inputGroup.fixed_inputs.collection, inputGroup.fixed_inputs.delimiters)
            setCollectionArr(res)
        }
    }, [])

    useEffect(() => {
        const combined = combineArrayWithDelimiters(
          collectionArr.filter(item => item.trim() !== ''),
          inputGroup.fixed_inputs.delimiters
        )

        setInputGroups((prevGroups) => {
            const newGroups = [...prevGroups]
              newGroups[index].fixed_inputs.collection = combined
              return newGroups
            } )

      }, [collectionArr, inputGroup.fixed_inputs.delimiters])
    
    const splitStringByDelimiters = (inputString, delimiters) => {
        if (!inputString || !Array.isArray(delimiters) || delimiters.length === 0) {
          throw new Error('Invalid input: Please provide a valid string and delimiters array.');
        }
      
        // Create a regular expression to match any of the delimiters
        const delimiterRegex = new RegExp(`[${delimiters.map((d) => d.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('')}]`, 'g');
      
        // Split the string using the regex
        return inputString.split(delimiterRegex).filter((item) => item.trim() !== '');
      };

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setInputGroups((prevGroups) => {
          const newGroups = [...prevGroups]
        //   if (name === 'collection') {
        //     newGroups[index].fixed_inputs.collection[collectionIndex] = value
        //   } 
        //   else {
            newGroups[index].fixed_inputs = {
              ...newGroups[index].fixed_inputs,
              [name]:name==="max_iterations"? Number(value) : value ,
            // }
          }
          return newGroups
        })
      }

      const handleCollection = (e, collectionIndex) => {
        const { name, value } = e.target
        const colArr = [...collectionArr]
        colArr[collectionIndex] = value
        setCollectionArr(colArr)
      }
    
      const addCollectionField = (position) => {
        // setInputGroups((prevGroups) => {
        //   const newGroups = [...prevGroups]
        //   newGroups[index].fixed_inputs.collection.splice(position, 0, '')
        //   return newGroups
        // })
        console.log(position)
        setCollectionArr((prevGroups) => {
            const newGroups = [...prevGroups]; // Create a copy of the previous array
            newGroups.splice(position, 0, ''); // Insert the new item at the specified position
            return newGroups; 
        })
      }
    
      const removeCollectionField = (position) => {
        // if (inputGroup.fixed_inputs.collection.length > 1) {
        //   setInputGroups((prevGroups) => {
        //     const newGroups = [...prevGroups]
        //     newGroups[index].fixed_inputs.collection.splice(position, 1)
        //     return newGroups
        //   })
        // }
        if (collectionArr.length > 1) {
            setCollectionArr((prevGroups) => {
              const newGroups = [...prevGroups]; // Create a copy of the array
              newGroups.splice(position, 1); // Remove the item at the specified position
              return newGroups; // Return the updated array
            });
          }
      }
    
      const handleDelimiterChange = (value) => {
        // const input = e.target.value;
        // const delimiters = input.split(',').map((d) => d.trim()); // Split and trim delimiters
        // const invalidDelimiters = delimiters.filter((d) => !allowedDelimiters.includes(d));
      
        // if (invalidDelimiters.length > 0) {
        //     addNewNotifcation(`Invalid delimiters: ${invalidDelimiters.join(', ')}`);
        //     return;
        // }
      
        setInputGroups((prevGroups) => {
          const newGroups = [...prevGroups];
          newGroups[index].fixed_inputs.delimiters = [value];
          return newGroups;
        });
      };

      const combineArrayWithDelimiters = (array, delimiters = [',']) => {
        if (array.length === 0) return ''
        if (delimiters.length === 0) return array.join(',')
    
        let result = array[0]
        let delimiterIndex = 0
    
        for (let i = 1; i < array.length; i++) {
          result += delimiters[delimiterIndex % delimiters.length] + array[i]
          delimiterIndex++
        }
    
        return result
      }

    return (
        <div className='w-full space-y-7'>
            <div className="">
                <Label htmlFor="collection">Collection</Label>
                <div className="grid grid-cols-3 gap-2 items-center">
                    {collectionArr.map((coll, collIndex) => (
                        <div key={collIndex} className="flex items-center space-x-1">
                            <Button
                                type="button"
                                variant="ghost"
                                size="sm"
                                onClick={() => addCollectionField(collIndex)}
                                className="h-9 px-2"
                                aria-label="Add field before"
                            >
                                <ArrowLeftCircle className="h-4 w-4 mr-1" />
                                {/* <span className="sr-only md:not-sr-only md:inline-block">Before</span> */}
                            </Button>
                            <div className="flex-grow flex items-center space-x-1 bg-white rounded-md border border-input">
                                <Input
                                    id={`collection-${collIndex}`}
                                    name="collection"
                                    value={coll}
                                    onChange={(e) => handleCollection(e, collIndex)}
                                    placeholder="Enter collection variable name"
                                    className="border-0 focus-visible:ring-0 focus-visible:ring-offset-0"
                                />
                            </div>
                            <Button
                                type="button"
                                variant="ghost"
                                size="sm"
                                onClick={() => addCollectionField(collIndex + 1)}
                                className="h-9 px-2"
                                aria-label="Add field after"
                            >
                                {/* <span className="sr-only md:not-sr-only md:inline-block">After</span> */}
                                <ArrowRightCircle className="h-4 w-4 ml-1" />
                            </Button>
                            {collectionArr.length > 1 && (
                            <Button
                                type="button"
                                variant="ghost"
                                size="sm"
                                onClick={() => removeCollectionField(collIndex)}
                                aria-label="Remove field"
                            >
                                <MinusCircle className="h-4 w-4" />
                            </Button>
                            )}
                        </div>
                        ))}
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <div className="">
                    <Label htmlFor="max_iterations">Max Iterations</Label>
                    <Input
                        id="max_iterations"
                        name="max_iterations"
                        type="number"
                        min={0}
                        value={inputGroup.fixed_inputs.max_iterations || ''}
                        onChange={handleInputChange}
                        placeholder="Enter maximum iterations"
                        className="bg-white"
                    />
                </div>
                <div className="">
                    <Label htmlFor="delimiters">Delimiters</Label>
                    {/* <Input
                        id="delimiters"
                        name="delimiters"
                        value={inputGroup.fixed_inputs.delimiters || ''}
                        onChange={handleDelimiterChange}
                        placeholder="Enter delimiters, e.g. ',', ';'"
                        className="bg-white"
                    /> */}
                    <Select onValueChange={handleDelimiterChange} 
                    id="step_type" 
                    className="w-full" value={inputGroup.fixed_inputs.delimiters[0]}
                    >
                        <SelectTrigger className="bg-white">
                            <SelectValue placeholder="Select step type" />
                        </SelectTrigger>
                        <SelectContent>
                            {allowedDelimiters.map((item) => (
                                <SelectItem key={item} value={item}>
                                    {item}
                                </SelectItem>))}
                           
                        </SelectContent>
                    </Select>
                </div>
                <div className="">
                    <Label htmlFor="break_condition">Break Condition</Label>
                    <Input
                        id="break_condition"
                        name="break_condition"
                        value={inputGroup.fixed_inputs.break_condition || ''}
                        onChange={handleInputChange}
                        placeholder="Enter break condition"
                        className="bg-white"
                    />
                </div>
                {/* next step variable */}
                {/* <div className="">
                    <NextStep
                    inputGroup={inputGroup}
                    inputGroups={inputGroups}
                    setInputGroups={setInputGroups}
                    index={index}
                    />
                </div> */}
            </div>

            <NestedSteps 
            parentId={parentId} 
            mainStepIndex={index} 
            inputGroup={inputGroup}
            inputGroups={inputGroups} 
            setInputGroups={setInputGroups}
            userInput={userInput}
            />
        </div>
    )
}

export default StepLoop