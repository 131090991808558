import { Dialog, DialogClose, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from 'components/ui/dialog'
import React, { useContext, useEffect, useState } from 'react'
import { ScrollArea } from "components/ui/scroll-area";
import { Button } from 'components/ui/button';
import { Context } from 'context/GlobalState';
import groqDetails from "../../json/groqDetails";  
import { ChevronDown } from 'lucide-react';
import { unifiedModelService } from 'api/services/PROJECT-O/UnifiedModel.service';
import watsonxModels from "../../json/watsonxModels";
import { AppLoader } from 'components/LoaderSpinner';
import { Badge } from 'components/ui/badge';
import { FilterBar, SearchBar } from '../Instructions/Filters';

const CommonModelSelectionModal = ({ onModelSelect, finalModel }) => {
    const [maxTokens, setMaxTokens] = useState(null);
    const [description, setDescription] = useState("");
    const [loading, setLoading] = useState(true)
    const [commonModels, setCommonModels] = useState([])
    const [recommendedTasks, setRecommendedTasks] = useState([]);
    const [selectedModel, setSelectedModel] = useState(finalModel || null);
    const [selectType, setSelectType] = useState("All");
    const [searchTerm, setSearchTerm] = useState("");

    const {addNewNotification} = useContext(Context)

    useEffect(() => {
        LoadGroqModels()
        if(finalModel?.modelName){
            setSelectedModel(finalModel)
        }
   
    }, [finalModel.modelName])

    const LoadGroqModels = async () => {
            try {
                const res = await unifiedModelService.listGroqModels()
                console.log('Groq API Response:', res); 
                const groqModels = res.data.models.data.map(item => ({...item, name: item.id, model_type: 'Groq'})).filter((model) => !model.id.includes("whisper")) || []
                const watsonModels = watsonxModels.map(item => ({...item, model_type: 'IBM', name: item.name.split('/')[1], owned_by: item.name.split('/')[0], id: item.name}))
                setCommonModels([...groqModels, ...watsonModels]);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false)
            }
    }
    
    // Handle model selection
    const handleModelSelect = (model) => {
        if (selectedModel?.modelName === model.id) {
            resetModelDetails();
        } else {
            setSelectedModel({modelName: model.id, modelType: model.model_type});
            if (model.model_type === 'IBM') {
                setRecommendedTasks(model.tasks);  
                setMaxTokens(model.max_tokens);  
                setDescription(model.description || ""); 
            } else if (model.model_type === 'Groq')  {
                setMaxTokens(model.context_window || null);
                const modelDetails = groqDetails.models.find(m => m.id === model.id) || {};
                setDescription(modelDetails.description || "No description available.");
                setRecommendedTasks(modelDetails.recommended_tasks || []);
            }
        }
    };

    const resetModelDetails = () => {
        setSelectedModel(null);
        setMaxTokens(null);
        setDescription("");
        setRecommendedTasks([]);
    };

    const handleDone = () => {
        if (!selectedModel?.modelName) {
            addNewNotification("Please select a model first.", "warning");
            return;
        }
        onModelSelect(selectedModel);
    };

    const handleFilterModels = () => {
        return commonModels.filter(model =>
            (model.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            model.owned_by.toLowerCase().includes(searchTerm.toLowerCase())) &&
            (selectType === 'All' || model.model_type === selectType) // search within selected type if not All
            );
    }

    // Handle search term change
    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    // Handle search term change
    const handleType = (value) => {
        setSelectType(value);
    };

    if (loading) {
        return <div className='flex flex-grow flex-col justify-center items-center'> <AppLoader size={50} /> </div>;
    }

    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button variant="outline" className="w-full">
                    <ChevronDown className="mr-2 h-4 w-4" />
                    {finalModel?.modelName ? (
                        <span> {finalModel?.modelName} {" - "} <small className='text-muted-foreground'> ({finalModel.modelType}) </small> </span>
                    ) : "Select a model"}
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[90vw] sm:max-h-[90vh]">
                <DialogHeader>
                    <DialogTitle>Select a Model</DialogTitle>
                    <DialogDescription>
                        Choose a model and see its details.
                    </DialogDescription>
                </DialogHeader>
                <div className="space-y-4">
                    <div className="flex flex-col md:flex-row gap-4">
                        <ScrollArea className="h-[400px] flex-grow">
                            <div className="mb-6 flex flex-col sm:flex-row gap-4 items-center p-4">
                                <div className="flex-grow">
                                    <SearchBar onSearch={handleSearch} />
                                </div>
                                <FilterBar
                                onSearch={handleType}
                                selectModelType={selectType}
                                />
                            </div>
                            <div className="grid grid-cols-3 grid-rows-5 gap-4 p-4">
                                {handleFilterModels().length > 0 ? (
                                    handleFilterModels().map((model) => (
                                        <Button
                                            key={model.id}
                                            variant={selectedModel?.modelName === model.id ? "default" : "outline"}
                                            onClick={() => handleModelSelect(model)}
                                            className="justify-start h-auto text-left"
                                        >
                                            <div className="w-full">
                                                <div className='flex w-full justify-between gap-2 items-center'>
                                                    <div className="font-semibold w-full truncate">{model.name}</div>
                                                    <Badge className="px-1 rounded text-green-50">
                                                        {model.model_type}
                                                    </Badge>
                                                </div>
                                                <div className="text-xs text-muted-foreground">{model.owned_by}</div>
                                            </div>
                                        </Button>
                                    ))
                                ) : (
                                    <p>No models available</p>
                                )}
                            </div>
                        </ScrollArea>
                        {selectedModel?.modelName && (
                            <div className="md:w-1/3 p-4 bg-muted rounded-md h-96 max-h-96 overflow-y-auto">
                                <h2 className="mb-2 font-semibold text-xl">{selectedModel?.modelName}</h2>

                                {description && (
                                    <div className="mb-4">
                                        <p>{description}</p>
                                    </div>
                                )}

                                {maxTokens && (
                                    <div>
                                        <h4 className="mb-2">
                                            <span className="font-semibold">Max Tokens:</span> {maxTokens}
                                        </h4>
                                    </div>
                                )}

                                {recommendedTasks.length > 0 && (
                                    <div>
                                        <h4 className="font-semibold">Recommended Tasks:</h4>
                                        <ul className="list-disc pl-5">
                                            {recommendedTasks.map((task, index) => (
                                                <li key={index}>{task}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="flex justify-end space-x-2"> 
                        <DialogClose asChild>
                            <Button type="button" variant="secondary">
                                Close
                            </Button>
                        </DialogClose>
                        {selectedModel?.modelName && (
                            <div className="flex justify-end">
                                <DialogTrigger asChild>
                                    <Button disabled={finalModel?.modelName === selectedModel?.modelName} variant="default" onClick={handleDone}>Select</Button>
                                </DialogTrigger>
                            </div>
                        )}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default CommonModelSelectionModal