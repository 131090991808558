/* eslint-disable max-lines */
import React, { useContext, useState } from 'react'
import WatsonXConfiguration from '../Common/WatsonXConfiguration';
import GroqConfiguration from '../Common/GroqConfiguration';
import AnatomylivePrompt from '../Common/AnatomyLivePrompt';
import labHelper from 'pages/PromptingAndRagTraining/classes/AILabHelper';
import { Separator } from 'components/ui/separator';
import { ArrowUpFromLine, WandSparkles, Globe } from 'lucide-react';
import { Button } from 'components/ui/button';
import { Output } from './Output';
import { unifiedModelWSService } from 'api/services/PROJECT-O/WEBSOCKETS/UnifiedModel.websocket.service';
import { Context } from 'context/GlobalState';
import CommonModelSelectionModal from '../Common/CommonModelSelectionModal';
import StyledTextArea from "../StyledTextArea";
import { RagModal } from '../Common/RAG/RagModal';
import { useParams } from 'react-router-dom';
import { checkRequiredFields } from '../Instructions/helper';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "components/ui/dialog";
import { Label } from 'components/ui/label';
import InputValidation from 'components/InputValidation';
import { Textarea } from 'components/ui/textarea';
import { instructionService } from 'api/services/BEX/instruction.service';
import { ResizablePanelGroup, ResizablePanel, ResizableHandle } from 'components/ui/resizable';

export default function InstructionForm({ form, setForm, update=false }) {

    const [output, setOutput] = useState('')
    const [btnLoading, setBtnLoading] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const [inputData, setInputData] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
    const { oragID, subOragID } = useParams()

    const openModal = () => setIsModalOpen(true)
    const closeModal = () => setIsModalOpen(false)
    const openSaveModal = () => setIsSaveModalOpen(true);
    const closeSaveModal = () => setIsSaveModalOpen(false);
    
    const { addNewNotifcation, user } = useContext(Context)

    const handleParameter = (data) => {
        if (form.model_type === 'IBM') {
            setForm(prev => ({...prev, watsonParameters: data}))
        } else if (form.model_type === 'Groq')  {
            setForm(prev => ({...prev, groqParameters: data}))
        }
    }

    const selectedModel = (data) => {
        setForm(prev => ({...prev, selectedModel: data.modelName, model_type: data.modelType}))
    }

    const handleSendPrompt = async () => {
    
        if (!form.selectedModel) {
            return addNewNotifcation("Please select a foundation model.", "warning");
        } else if (!form.instruction.trim()) {
            return addNewNotifcation("Please enter an instruction.", "warning");
        }

        try {
            setBtnLoading(true)
            setOutput("")
            
            let params = form?.watsonParameters;
            if (form.model_type === 'IBM') {
                if (form.watsonParameters.mode === "greedy") {
                    params = Object.fromEntries(
                        Object.entries(form.watsonParameters).filter(([key]) => key === "max_new_tokens" || key === "mode" || key === "stop_sequences")
                    );
                }
            }
            
            const body = {
                "modelId": form.selectedModel,
                "prompt": labHelper.ConstructPromptUnified({
                    "instruction": form.instruction.trim(),
                    "context": form.context.trim(),
                    "target_format": form.targetFormat.trim(),
                    "sample_input": form.sampleInput.trim(),
                    "sample_output": form.sampleOutput.trim(),
                }),
                "parameters": form.model_type === 'IBM' ? params : form.groqParameters,
                "query": inputData.trim()
            }
            
            console.log("Sent Prompt\n", body.prompt);
            const res = await unifiedModelWSService.experiment(body, form.model_type);
            if (typeof res === "object" && res.model_type && res.result.prediction) {
                setOutput(res.result.prediction);
            } else {
                throw new Error(res);
            }
        } catch (error) {
            console.log('error: ', error);
            addNewNotifcation("Error generating response.", "danger");
        } finally {
            setBtnLoading(false)
        }
    }

    // Save
    const handleCreateInstruction = (unique_name, modelDescription) => {
        const requiredFields = [
            {field: 'model_type', name: 'model type'},
            {field: 'unique_name', name: 'unique name'},
            {field: 'modelId', name: 'model'},
        ]
        
        const instructionObj = {
            "model_type": form.model_type,
            "unique_name": unique_name,
            "modelId": form.selectedModel,
            "prompt": labHelper.ConstructPromptUnified({
                "instruction": form.instruction.trim(),
                "context": form.context.trim(),
                "target_format": form.targetFormat.trim(),
                "sample_input": form.sampleInput.trim(),
                "sample_output": form.sampleOutput.trim(),
            }),
            "parameters": form.model_type === 'IBM' ? form.watsonParameters : form.groqParameters,
            "org_id": oragID,
            "sub_org_id": subOragID,
            "modelDescription": modelDescription,
            "user_id": user._id,
            "placeholders": {},
            "metadata": {}
        }
        // console.log(instructionObj)
        
        const checkFields = checkRequiredFields(instructionObj, requiredFields)
        if (!checkFields.isValid) {
            return addNewNotifcation(`Please fill in all required fields: ${checkFields.missingFields.map(item => (item.name)).join(', ')}`, 'warning')
        } else if (!form.instruction || form.instruction.trim() === '') {
            return addNewNotifcation(`Please fill in all required fields: instruction`, 'warning')
        }

        setSaveLoading(true)
        
        instructionService.createModel(instructionObj)
        .then((res) => {
            console.log('res: ', res);
            addNewNotifcation('Instruction created successfully', 'success')

        }).catch((error) => {
            console.log('error: ', error);
            if (error.response?.status === 409) { 
                addNewNotifcation(`Instruction with ${unique_name} already exists`, 'danger')
            } else {
                addNewNotifcation(`Something went wrong. Please try again`, 'danger')
            }
        }).finally(() => {
            setSaveLoading(false)
            setIsSaveModalOpen(false);
        })
    }

    // anatomy action buttons
    const actions = {
        instruction: [
            {icon: <WandSparkles size={18}/>, onClick: () => {alert("clicked")}, disabled: true, visible: false}
        ],
        context: [
            {icon: <Globe size={18}/>, onClick: () => {alert("clicked")}, disabled: true, visible: false},
            {icon: <span className=''>RAG</span>, onClick: openModal, disabled: false, visible: true}
        ],
        inputData: [
            {icon: <ArrowUpFromLine size={18}/>, onClick: () => {alert("clicked")}, disabled: true, visible: false},
            {icon: <span className='text-xs'>RAG</span>, onClick: () => {alert("clicked")}, disabled: true, visible: false}
        ]
    }


    return (
        <div className="flex flex-row gap-4 w-full">
            <ResizablePanelGroup direction="horizontal" className="w-full gap-2">
                <ResizablePanel defaultSize={50} minSize={25} className='flex flex-col gap-4 w-full'>
                    <div className="rounded-xl border border-solid bg-card text-card-foreground shadow rounded-md px-4 py-6 h-fit">
                        <div className='flex gap-2'>
                            <div className="w-full">
                                <CommonModelSelectionModal
                                finalModel={{modelName: form.selectedModel, modelType: form.model_type}}
                                onModelSelect={selectedModel}
                                />
                            </div>

                            {form.model_type === 'IBM' ? (
                                <WatsonXConfiguration
                                parametersWatsonX={form.watsonParameters} 
                                setParametersWatsonX={handleParameter} 
                                selectedModel={form.selectedModel}
                                />
                            ) : form.model_type === 'Groq' ? (
                                <GroqConfiguration
                                parametersGroq={form.groqParameters} 
                                setParametersGroq={handleParameter} 
                                />
                            ) : ''}
                            
                        </div>

                        <Separator className='my-3' />

                        <AnatomylivePrompt instructionForm={form} setInstructionForm={setForm} isExecute={false} actions={actions}/>
                        
                        <div className="flex flex-row justify-end gap-2 mt-4 px-1">
                            {/* <Button className='px-1' size='icon' variant="outline">
                                <Download size={18} className='' />
                            </Button> */}
                            {/* <Button className='px-1 w-[70px]' size='icon' variant="outline" disabled={saveLoading} onClick={handleCreateInstruction}>
                                {saveLoading ? 'Saving instruction...' : 'Save'}
                            </Button> */}
                            <Button onClick={openSaveModal}>
                                Save instruction
                            </Button>
                        </div>

                    </div>

                    <div className="rounded-xl border border-solid bg-card text-card-foreground shadow rounded-md px-4 py-6 h-fit">
                        <div className='flex flex-col gap-4 w-full p-1'>
                            {/* <Input value={inputData} onChange={(e) => setInputData(e.target.value)} placeholder='input data' /> */}

                            <StyledTextArea
                                value={inputData}
                                onChange={(e) => setInputData(e.target.value)}
                                labelText="Input Data"
                                tooltipText="Include any data or details that the language model needs to process in order to generate
                                a meaningful response. This can include text, numbers, or structured data."
                                required={false}
                                actions={actions?.inputData || []}
                            />
                        </div>

                        <div className="flex flex-row justify-end gap-3 mt-2 px-1">
                            <Button disabled={btnLoading} onClick={openModal} variant='outline'>
                                RAG
                            </Button>
                        </div>
                    </div>
                    {/* RAG modal  */}
                    <RagModal isOpen={isModalOpen} onClose={closeModal} setForm={setForm} />
                    <SaveModal isOpen={isSaveModalOpen} onClose={closeSaveModal} onSave={handleCreateInstruction} loading={saveLoading}/>
                </ResizablePanel>
                <ResizableHandle />
                <ResizablePanel defaultSize={50} minSize={25} className="w-full">
                    <Output output={output} handleSendPrompt={handleSendPrompt} btnLoading={btnLoading}/>
                </ResizablePanel>
            </ResizablePanelGroup>
        </div>
    )
}


function SaveModal({ isOpen, onClose, onSave, loading }) {
    const [uniqueName, setUniqueName] = useState('');
    const [modelDescription, setModelDescription] = useState('');

    const handleSave = () => {
        onSave(uniqueName, modelDescription);
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[600px] select-none">
                <DialogHeader>
                    <DialogTitle>Save Instruction</DialogTitle>
                    <DialogDescription>
                        Please make sure that you have experimented with your instruction and are satisfied with the output it is giving.
                    </DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="name" className="text-right">
                            Unique Name
                        </Label>
                        <InputValidation
                            id="name"
                            value={uniqueName}
                            onChange={(e) => setUniqueName(e.target.value)}
                            className="col-span-3"
                            placeholder="Enter a unique name for your instruction"
                        />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="description" className="text-right">
                            Description
                        </Label>
                        <Textarea
                            id="description"
                            value={modelDescription}
                            onChange={(e) => setModelDescription(e.target.value)}
                            className="col-span-3"
                            placeholder="Enter description for your instruction"
                        />
                    </div>
                </div>
                <div className="modal-actions flex flex-row gap-2 items-center">
                    <Button className='px-2 w-fit min-w-[70px]' size='icon' variant="default" disabled={loading} onClick={handleSave}>
                        {loading ? 'Saving instruction...' : 'Save'}
                    </Button>
                    <Button variant="outline" onClick={onClose}>Cancel</Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}