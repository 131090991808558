/* eslint-disable max-lines */
import { useCallback, useContext, useEffect, useState } from "react"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select"
import { Input } from "components/ui/input"
import { Label } from "components/ui/label"
import handler from "../../OpCodeBuilderHAndC/OpCodeBuilderHandler"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogTrigger } from "components/ui/dialog"
import { Button } from "components/ui/button"
import { OpCodeBuilderContext, operatorOptions, sideOptions } from "../../OpCodeBuilderHAndC/OpCodeBuilderContext"
import { Edit, PlusIcon } from "lucide-react"
import { Context } from "context/GlobalState"
import { ContextSelector } from "../ContextSelector"

export default function ConditionBuilder({inputGroups, stepIndex, onConditionChange, setInputGroups, initialCondition}) {
    const [leftSide, setLeftSide] = useState("static")
    const [rightSide, setRightSide] = useState("static")
    const [operator, setOperator] = useState(">")
    const [leftValue, setLeftValue] = useState("")
    const [rightValue, setRightValue] = useState("")
    const [isFirstRender, setIsFirstRender] = useState(true)
    // const [formattedCondition, setFormattedCondition] = useState("")
    const previousOutputs = handler.getPreviousOutputs(inputGroups, stepIndex)

    const parseCondition = useCallback(
        (conditionString) => {
            const regex =
            /^(?:'([^']*)'|"([^"]*)"|(\{\{[^}]+\}\}))?\s*([<>=!]+|(?:not\s+)?in|is(?:\s+not)?)\s*(?:'([^']*)'|"([^"]*)"|(\{\{[^}]+\}\}))?$/
          const match = conditionString.match(regex)
          console.log(match, conditionString)
    
          if (match) {
            const [, leftSingleQuote, leftDoubleQuote, leftDynamic, op, rightSingleQuote, rightDoubleQuote] =
              match
    
            let newLeftSide = "static"
            let newLeftValue = leftSingleQuote || leftDoubleQuote || ""
            let newRightSide = "static"
            let newRightValue = rightSingleQuote || rightDoubleQuote || ""
            const newOperator = op || ""
            console.log(leftDynamic)
    
            if (leftDoubleQuote) {
              if (leftDoubleQuote.startsWith("{{context['real_time_data']}}")) {
                newLeftSide = "realtime"
                newLeftValue = "real_time_data"
              } else if (leftDoubleQuote.startsWith("{{context[")) {
                console.log("run")
                newLeftSide = "context"
                newLeftValue = leftDoubleQuote.match(/context\['([^']+)'\]/)?.[1] || ""
              } else if (leftDoubleQuote.startsWith("{{output[")) {
                newLeftSide = "output"
                newLeftValue = leftDoubleQuote.match(/output\['([^']+)'\]/)?.[1] || ""
              }
            }
    
            if (rightDoubleQuote) {
              if (rightDoubleQuote.startsWith("{{context['real_time_data']}}")) {
                newRightSide = "realtime"
                newRightValue = "real_time_data"
              } else if (rightDoubleQuote.startsWith("{{context[")) {
                newRightSide = "context"
                newRightValue = rightDoubleQuote.match(/context\['([^']+)'\]/)?.[1] || ""
              } else if (rightDoubleQuote.startsWith("{{output[")) {
                newRightSide = "output"
                newRightValue = rightDoubleQuote.match(/output\['([^']+)'\]/)?.[1] || ""
              }
            }
    
            setLeftSide(newLeftSide)
            setLeftValue(newLeftValue)
            setOperator(newOperator)
            setRightSide(newRightSide)
            setRightValue(newRightValue)
            }
        },[])
    
    useEffect(() => {
        if(isFirstRender){
            if (initialCondition) {
                parseCondition(initialCondition)
            }

            setIsFirstRender(false)
        }
    }, [initialCondition])

    const formatValue = (side, value) => {
        if (value.trim() === "") {
          return ""
        }
        switch (side) {
          case "context":
            return `"{{context['${value}']}}"`
          case "static":
            return `'${value}'`
          case "output":
            return `"{{output['${value}']}}"`
          case "realtime":
            return `"{{context['real_time_data']}}"`
          default:
            return value
        }
    }
    
    useEffect(() => {
        if(isFirstRender){
            return
        }

        const formattedLeft = formatValue(leftSide, leftValue)
        const formattedRight = formatValue(rightSide, rightValue)
        let newFormattedCondition = ""

        // if (formattedLeft && formattedRight) {
            newFormattedCondition = `${formattedLeft}  ${operator}  ${formattedRight}`
        // } else if (formattedLeft) {
        //     newFormattedCondition = formattedLeft
        // } else if (formattedRight) {
        //     newFormattedCondition = formattedRight
        // }

        // setFormattedCondition(newFormattedCondition)

        if (onConditionChange) {
            onConditionChange(newFormattedCondition)
        }
    }, [leftSide, rightSide, operator, leftValue, rightValue])

    const renderSide = (side) => {
        const currentSide = side === "leftSide" ? leftSide : rightSide
        const currentValue = side === "leftSide" ? leftValue : rightValue
        const setCurrentSide = side === "leftSide" ? setLeftSide : setRightSide
        const setCurrentValue = side === "leftSide" ? setLeftValue : setRightValue

        const handleOptSelection = (value) => {
            if(value === "realtime"){
                setCurrentValue("real_time_data"); 
            }else {
                setCurrentValue(""); 
            }
            setCurrentSide(value)
        }

        return (
            <div className="flex-1 w-full space-y-2">
                <Label htmlFor={`${side}-select`}>{side === "leftSide" ? "Left Side" : "Right Side"}</Label>
                <Select value={currentSide} onValueChange={(value) => handleOptSelection(value)}>
                    <SelectTrigger id={`${side}-select`} className="bg-white">
                        <SelectValue placeholder={`Select ${side === "leftSide" ? "left" : "right"} side`} />
                    </SelectTrigger>
                    <SelectContent>
                        {sideOptions.map((option) => (
                            <SelectItem key={option.value} value={option.value}>
                                {option.label}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
                {currentSide === "context" && 
                    <ContextModal 
                    currentValue={currentValue} 
                    onSelect={(context) => setCurrentValue(context)} 
                    inputGroups={inputGroups}
                    setInputGroups={setInputGroups}
                    initialCondition={initialCondition}
                    side={side}
                    />
                }
                {currentSide === "static" && (
                <Input
                    placeholder="Enter static value"
                    value={currentValue}
                    className="bg-white"
                    onChange={(e) => setCurrentValue(e.target.value)}
                />
                )}
                {currentSide === "output" && (
                <Select value={currentValue} onValueChange={setCurrentValue}>
                    <SelectTrigger className="bg-white">
                        <SelectValue placeholder="Select previous output" />
                    </SelectTrigger>
                    <SelectContent>
                        {previousOutputs.map((output) => (
                            <SelectItem key={output.stepId} value={output.prevOutp.value}>
                                {output.prevOutp.label}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
                )}
                {currentSide === "realtime" && (
                <Input placeholder="Real Time Data" value="real_time_data" className="bg-white" disabled/>
                )}
            </div>
        )
    }

    // const isUnaryOperator = (op) => ["not"].includes(op)

    return (
        <div className="flex flex-wrap items-end gap-4">
            {renderSide("leftSide")}

            <div className="flex-1 flex-col w-full ">
                <Label htmlFor="operator">Operator</Label>
                <Select  value={operator} onValueChange={(value) => setOperator(value)}>
                    <SelectTrigger id="operator" className="bg-white mt-[50px]">
                        <SelectValue placeholder="Select operator" />
                    </SelectTrigger>
                    <SelectContent>
                        {operatorOptions.map((option) => (
                            <SelectItem key={option.value} value={option.value}>
                                {option.label}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>

            {/* {!isUnaryOperator(operator) && renderSide("rightSide")} */}
            {renderSide("rightSide")}
        </div>
    )
}


const ContextModal = ({currentValue, onSelect, inputGroups, setInputGroups, initialCondition, side}) => {
    const {globalContext, actions} = useContext(OpCodeBuilderContext)
    const [contextField, setContextField] = useState("")
    const [isEdit, setIsEdit] = useState(false)
    const {addNewNotifcation} = useContext(Context)
    const isLeftSide = side === "leftSide"
    
    useEffect(() => {
        const parts = handler.parseCondition(initialCondition)
        const {left, right} = parts

        const leftBooleanValue = left !== `""` && left !== "" && left !== undefined
        const rightBooleanValue = right !== `""` && right !== "" && right !== undefined
        
        const value = isLeftSide? leftBooleanValue? currentValue : "" : rightBooleanValue? currentValue : ""
        const editValue = isLeftSide? leftBooleanValue? true : false : rightBooleanValue? true : false

        // if(currentValue){
        setContextField(value)
        setIsEdit(editValue)
        onSelect(value)
        // }
    }, [initialCondition])
    
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
          e.preventDefault()
          addContextField()
        }
    }

    const addContextField = () => {
        // const oldValue = currentValue

        if(contextField === "real_time_data"){
            addNewNotifcation("This key 'real_time_data' is reserved and cannot be used.", "warning")
            return
        }

        if(globalContext.includes(contextField)){
            addNewNotifcation("This key already exists, please use different key.", "warning")
            return
        }

        if(contextField){
            setIsEdit(true)
            actions({type:"SET_GLOBAL_CONTEXT", payload:[...globalContext, contextField]})
            // actions({type:"SET_GLOBAL_CONTEXT", payload:[...globalContext.filter((c) => c !== oldValue), contextField]})
            // handler.removeSpecificContextFromOtherSteps(setInputGroups, inputGroups, oldValue)
            onSelect(contextField)
        }
    }

    const handleAddFromOtherSteps = (field, checked) => {
        onSelect(checked? field : "")
        setContextField(checked? field : "")
        setIsEdit(checked? true : false)
        // actions({type:"SET_GLOBAL_CONTEXT", payload:[...globalContext.filter((c) => c !== currentValue)]})
    }

    const onRemoveContext = (contextToRemove) => {
        const isSameContext = contextToRemove === currentValue
        onSelect(isSameContext? "" : currentValue)
        setIsEdit(isSameContext? false : true)
        setContextField(isSameContext? "" : currentValue)
        actions({type:"SET_GLOBAL_CONTEXT", payload:globalContext.filter((c) => c !== contextToRemove)})
        handler.removeSpecificContextFromOtherSteps(setInputGroups, inputGroups, contextToRemove)
    }

    const handleRemove = () => {
        setIsEdit(false)
        setContextField("")
        onSelect("")
    }

    const sortedAndFilteredContext = (searchTerm) => {
        return globalContext?.filter((field) => field !== "real_time_data")
        .filter((field) => field?.toLowerCase().includes(searchTerm.toLowerCase()))
    }
    
    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button variant="outline" className="w-full">
                    {currentValue? `A context key added` : "Add Context" }
                </Button>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Manage global context fields available across all steps</DialogTitle>
                    <DialogDescription>You can add or select one context field.</DialogDescription>
                </DialogHeader>
                <div className="space-y-4">
                    <div className="flex space-x-2">
                        <Input
                        value={contextField}
                        onChange={(e) => setContextField(e.target.value.trim())}
                        onKeyPress={handleKeyPress}
                        placeholder="Enter context field"
                        readOnly={isEdit}
                        />
                        {isEdit? (
                            <Button onClick={()=> setIsEdit(false)} className="flex gap-1">
                                <Edit className="h-4 w-4" /> Edit
                            </Button>
                        ) : (
                            <Button disabled={contextField.trim() === ''} onClick={addContextField}>
                                <PlusIcon className="h-4 w-4" /> Add
                            </Button>
                        )}
                        {currentValue && 
                            <Button onClick={handleRemove} className="flex gap-1">
                                Remove
                            </Button>
                        }
                    </div>
                </div>
                <ContextSelector
                contextFields={currentValue}
                onAddContext={handleAddFromOtherSteps}
                onRemoveContext={onRemoveContext}
                sortedAndFilteredContext={sortedAndFilteredContext}
                inputGroups={inputGroups}
                isCondition={true}
                />
            </DialogContent>
        </Dialog>
    )
}


