import { useContext, useEffect, useMemo, useState } from 'react'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "components/ui/dialog"
import TuningForm from './TuningForm'
import { ScrollArea } from 'components/ui/scroll-area'
import { tuningLabService } from 'api/services/BEX/tuningLab.service'
import { Context } from 'context/GlobalState'
import { useParams } from 'react-router-dom'
import AlertModal from 'components/Alertmodal'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import TuningInfoCard from './TuningInfoCard'
import { Plus, Search } from 'lucide-react'
import { Input } from 'components/ui/input'
import { Button } from 'components/ui/button'
import { LoaderModalWithChild, LoadingIndicator } from 'components/LoaderModal'
import { PageLayout, PageContent } from 'components/NewDesignComponents/PageLayout'
import Breadcrumb from 'components/Breadcrumb'

export default function TuningExperiment() {
    const [experiments, setExperiments] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [editingExperiment, setEditingExperiment] = useState(null)
    const [openDelModal, setOpenDelModal] = useState(false)
    const [delExperimentngLoading, setDelExperimentngLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [deletedExper, setDeletedExper] = useState(null)
    const [searchTerm, setSearchTerm] = useState('')
    const {addNewNotifcation, user} = useContext(Context)
    const params = useParams()

    const filteredExperiments = useMemo(() => {
      return experiments.filter(experiment => 
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        experiment.deployment_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        experiment.tuning_method.toLowerCase().includes(searchTerm.toLowerCase()) ||
        experiment.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
        experiment.summary.toLowerCase().includes(searchTerm.toLowerCase())
      )
    }, [experiments, searchTerm])

    useEffect(() => {
        const getUserExperiments = async () => {
            try{
                setLoading(true)
                const res = await tuningLabService.getUserTuningExperiments(params.oragID, params.subOragID, user.id)
                console.log(res)
                setExperiments(res.data.map(experiment => ({...experiment, id:experiment._id})))
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }

        getUserExperiments()
    }, [])
     
    const handleAddExperiment = (newExperiment) => {
        setExperiments([...experiments, newExperiment])
        setIsModalOpen(false)
        setEditingExperiment(null)
    }

    const handleUpdateExperiment = (existingExperiment) => {
        const newExperiments = experiments.map(exp => exp._id === existingExperiment._id? existingExperiment : exp )
        setExperiments(newExperiments)
        setIsModalOpen(false)
        setEditingExperiment(null)
    }

    const handleViewExperiment = (id) => {
        const foundExperiment = experiments.find(exp => exp._id === id)
        setEditingExperiment(foundExperiment)
        setIsModalOpen(true)
    }

    const handleDelete = (id) => {
        setDeletedExper(experiments.find(item => item._id === id))
        setOpenDelModal(true)
    }

    const handleDeleteExperiment = async () => {
        try{
            setDelExperimentngLoading(true)
            const res = await tuningLabService.deleteTuningExperiment(params.oragID, params.subOragID, deletedExper.id, user._id)
            console.log(res)
            setExperiments(experiments.filter(experiment => experiment.id !== deletedExper.id))
            addNewNotifcation("The experiment deleted successfully", 'success')
        } catch (error) {
            console.log(error)
        } finally {
            setDelExperimentngLoading(false)
            setOpenDelModal(false)
        }
    }

    return (
        <PageLayout>
            <PageContent>
                <div className="flex flex-col w-full h-full">
                    <div className='my-3 px-2'>
                        <Breadcrumb maxPaths={3} />
                    </div>
                    <Card className="select-none relative w-full">
                        <CardHeader>
                            <CardTitle className="text-2xl font-bold">Tuning Lab</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
                                <DialogContent className="p-0 max-w-[1200px] h-[90vh]">
                                    <ScrollArea className='p-4 relative h-full'>
                                        <DialogHeader>
                                            <DialogTitle>{editingExperiment ? 'Edit Experiment' : 'Add New Experiment'}</DialogTitle>
                                            <DialogDescription>
                                                {editingExperiment ? 'Edit the details of your experiment.' : 'Enter the details for your new tuning experiment.'}
                                            </DialogDescription>
                                        </DialogHeader>
                                        <TuningForm 
                                        onSubmit={editingExperiment?  handleUpdateExperiment : handleAddExperiment} 
                                        editingExperiment={editingExperiment} 
                                        experiments={experiments}
                                        />
                                    </ScrollArea>
                                </DialogContent>
                            </Dialog>

                            <AlertModal 
                            setopenModal={setOpenDelModal} 
                            openModal={openDelModal} 
                            loading={delExperimentngLoading} 
                            onDelete={handleDeleteExperiment} 
                            />

                            <div className="flex items-center gap-2">
                                <div className="relative flex-1">
                                    <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
                                    <Input
                                        type="text"
                                        placeholder="Search experiment..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className="pl-8 w-full"
                                    />
                                </div>
                                <Button onClick={() => { setEditingExperiment(null); setIsModalOpen(true)}}>
                                    <Plus className="mr-2 h-4 w-4" /> Add Experiment
                                </Button>
                            </div>

                            {loading && (
                                <LoaderModalWithChild>
                                    <LoadingIndicator />
                                </LoaderModalWithChild>
                            ) }

                            <div className='h-[350px] mt-5'>
                                {filteredExperiments.length > 0 && (
                                    <ScrollArea className="h-full pr-2">
                                        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                                            {filteredExperiments.map((experiment) => (
                                                <TuningInfoCard 
                                                    key={experiment.id} 
                                                    experiment={experiment} 
                                                    onViewEdit={handleViewExperiment}
                                                    onDelete={handleDelete}
                                                />
                                            ))}
                                        </div>
                                    </ScrollArea>
                                )}
                                {!loading && filteredExperiments.length === 0 &&
                                <div className='flex justify-center items-center h-full'>
                                    <p className="text-gray-500">No experiments found.</p>
                                </div>
                                }
                            </div>

                        </CardContent>
                    </Card>
                </div>
            </PageContent>
        </PageLayout>
        
    )
}

